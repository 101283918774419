/* eslint-disable react/jsx-filename-extension */
import {
    Hero, SEO, Waistband, Modal, Log,
} from '@brandedholdings/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import * as queryString from '@brandedholdings/query-string';
import PagePost from '../../models/PagePost';
import { mediaNet as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import ResultsForm from '../../components/ResultsForm';
import { InterlincxContainerV2 } from '../../components/Interlincx/InterlincxContainerV2';
import { InterlincxDirectOffer } from '../../components/Interlincx/InterlincxDirectOffer';

class HorizonPage extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            modalIsOpen: false,
            clickedElement: null,
            modalEvaluated: false,
        };
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    openModal = (e, offer) => {
        e.preventDefault();
        if ('history' in window) window.history.pushState({}, '', `${document.location.search}#credit-report-step`);
        Log.toAnalyticsEvent('Offer', 'Select', `${offer.body.offer_name} - ${offer.banner_id}`, true);
        this.modalRef.current.openModal();
    };

    onModalFormSubmit = () => {
        const { clickedElement } = this.state;
        console.log('clickedElement', clickedElement);
        clickedElement.click();
        this.setState({ modalEvaluated: true, modalIsOpen: false });
    };

    render() {
        const loc = this.props.location;
        const parsed = queryString.parse(loc.search);
        const { zone } = parsed;
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const template = 'hideNavigation';
        const { modalIsOpen } = this.state;

        return (
            <PageWrapper location={this.props.location} templateType={template} adserve>
                <SEO post={post} />
                <Hero
                    heading="We're Connecting You!"
                    subheading="Here's an exclusive offer from our trusted partner"
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero__offset hero__condensed"
                    actionModifiers={null}
                />
                <div className="processing processing--dots">
                    <div />
                    <div />
                    <div />
                </div>
                <Waistband>
                    <div className="layout">
                        <div id="results-container">
                            <InterlincxContainerV2 onInterlincxClick={this.onInterlincxClick} useRawConfig>
                                { ({ interlincxConfig }) => (
                                    <InterlincxDirectOffer config={interlincxConfig} zone={zone || 'horizon'} />
                                )}
                            </InterlincxContainerV2>
                        </div>
                    </div>
                </Waistband>
                <Modal
                    isModalOpen={modalIsOpen}
                    heading={false}
                    triggerOnClick
                    className="form-modal"
                    onClose={this.onModalFormSubmit}
                    ref={this.modalRef}
                >
                    <ResultsForm onSubmit={this.onModalFormSubmit} onCloseModal={this.onModalFormSubmit} />
                </Modal>
            </PageWrapper>
        );
    }
}

HorizonPage.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object,
};

export default HorizonPage;
